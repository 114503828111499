<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardBody v-if="isLoading">
            <MbnSpinner />
          </CCardBody>

          <CCardBody v-if="error">
            <MbnErrorMessage />
          </CCardBody>

          <CampaignForm
            v-if="campaign"
            :is-sending="isSending"
            :initial-values="initialValues"
            @formSubmit="onSubmit"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { GET_CAMPAIGN, EDIT_CAMPAIGN } from '@/store/action-types/campaigns';
import CampaignForm from './CampaignForm';
import MbnSpinner from '@/components/Spinner';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'CampaignEdit',
  components: {
    CampaignForm,
    MbnSpinner,
    MbnErrorMessage,
  },
  computed: {
    ...mapState('campaigns', ['isLoading', 'isSending', 'error']),
    ...mapGetters('campaigns', ['campaign']),
    recordId() {
      return this.$route.params.id;
    },
    initialValues() {
      return {
        active: this.campaign.active,
        browser: this.campaign.browser || '',
        country: this.campaign.country || '',
        language: this.campaign.language || '',
        name: this.campaign.name,
        platform: this.campaign.platform || '',
        siteWhiteList: this.campaign.siteWhiteList || '',
        capping: this.campaign.capping,
        userId: this.campaign.userId,
      };
    },
  },
  created() {
    this.GET_CAMPAIGN(this.recordId);
  },
  methods: {
    ...mapActions('campaigns', [GET_CAMPAIGN, EDIT_CAMPAIGN]),
    onSubmit(formData) {
      this.EDIT_CAMPAIGN({ id: this.recordId, formData })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.$router.push({ name: 'campaigns' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
